<template>
	<div class="flex flex-col">
		<span class="font-semibold text-xl">Entry settings</span>
		<div :class="{ 'col-span-12': true, rounded: true, border: true, 'b-2': true, 'py-2': true, 'px-2': true, 'mt-4': true, 'mb-4': true }">
			<FormKit v-model="settings" type="group" name="entry" :actions="false">
				<FormKit type="dropdown" name="exit_point" label="Select an exit point" popover :options="options">
					<!--SELECTION SLOT-->
					<template #selection="{ option }">
						<div class="flex items-center">
							<FontAwesomeIcon
								:icon="faPersonFromPortal"
								class="flex items-center justify-center w-5 h-full pointer-events-none mx-2 ml-4"
								:style="{
									color: option.color,
								}"
							/>
							<span>
								{{ option.label }}
							</span>
						</div>
					</template>
					<!---->
					<!--OPTION SLOT-->
					<template #option="{ option, classes }">
						<div :class="`${classes.option} flex items-center`">
							<FontAwesomeIcon
								:icon="faPersonFromPortal"
								class="flex items-center justify-center w-5 h-full pointer-events-none mx-2"
								:style="{
									color: option.color,
								}"
							/>
							<span>
								{{ option.label }}
							</span>
						</div>
					</template>
					<!---->
				</FormKit>
			</FormKit>
			<FormKit
				name="notes"
				type="textarea"
				:classes="{
					outer: 'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-12',
				}"
				label="Builder notes"
				placeholder="Optional notes about the action"
			/>
			<DebugSection>{{ workflowStore.editingWorkblock }}</DebugSection>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, ref } from 'vue';
	import { faPersonFromPortal } from '@fortawesome/pro-solid-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { useWorkflowStore } from '@modules/workflow/store';
	import DebugSection from '@components/DebugSection.vue';

	const workflowStore = useWorkflowStore();
	const settings = ref(workflowStore.editingWorkblock);

	const options = computed(() => {
		const exits = [];
		for (const exit in workflowStore.workflowExits) {
			exits.push({
				label: workflowStore.workflowExits[exit].label,
				color: workflowStore.workflowExits[exit].color,
				value: exit,
			});
		}
		return exits;
	});
</script>
