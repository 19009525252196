<template>
	<div class="flex flex-col">
		<div class="mb-2">
			<TabPanel v-model="tab" :tabs="tabs" />
		</div>
		<section v-for="(workblockActionSectionLabel, workblockActionSection) in WorkblockSections" :key="workblockActionSection">
			<template v-if="tab === workblockActionSectionLabel">
				<span class="font-semibold text-xl">{{ workblockActionSectionLabel }}</span>
				<FormKit v-if="workblockActions[workblockActionSection].length > 0" v-model="workblockActions" type="group">
					<FormKit id="repeater" v-slot="{ value, index }" :add-button="false" :name="workblockActionSection" type="repeater">
						<FormKit
							name="actionId"
							type="dropdown"
							:classes="{
								outer: 'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-12',
							}"
							label="Workblock action"
							:options="[]"
							placeholder="Select an action to be performed"
							:meta="{
								listType: 'dynamic',
								dynamicList: 'active_workblocks',
							}"
							@input="(newActionId) => updateWorkblockAction(value.id, index, String(newActionId))"
						/>
						<hr />
						<DebugSection title="Parameters">{{ value.parameters }}</DebugSection>
						<FormKit v-if="value.actionId && workblockActionSchema[value.id + '_' + value.actionId]" :key="value.id + '_' + value.actionId" type="group" :preserve="true" name="parameters">
							<FormKitSchema :schema="workblockActionSchema[value.id + '_' + value.actionId]" :data="value.parameters" />
						</FormKit>
						<FormKit
							name="notes"
							type="textarea"
							:classes="{
								outer: 'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-12',
							}"
							label="Builder notes"
							placeholder="Optional notes about the action"
						/>
					</FormKit>
				</FormKit>
				<button
					class="flex justify-center items-center px-3 py-1.5 mb-3 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary hover:bg-gray-100 focus:outline-none"
					type="button"
					@click="addNewActionToSection(workblockActionSection)"
				>
					Add new action
				</button>
				<DebugSection title="Workblock Actions">{{ workblockActions[workblockActionSection] }}</DebugSection>
			</template>
		</section>
		<section v-if="tab == 'Settings'">
			<span class="font-semibold text-xl">Workblock settings</span>
			<div :class="{ 'col-span-12': true, rounded: true, border: true, 'b-2': true, 'py-2': true, 'pl-2': true, 'mt-4': true, 'mb-4': true }">
				<FormKit v-model="settings" type="form" :actions="false">
					<div class="grid grid-cols-12 gap-x-2">
						<FormKitSchema :schema="workblockSettingsSchema" />
					</div>
				</FormKit>
				<DebugSection>{{ workflowStore.editingWorkblockTemplate }}</DebugSection>
			</div>
		</section>
	</div>
</template>

<script setup lang="ts">
	import { computed, onBeforeMount, onMounted, ref } from 'vue';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { WorkflowElementActions } from '@/types/workflow';
	import { TabPanelTab } from '@/types/layout';
	import { faFolderGear, faPlay, faTurnDownLeft, faTurnDownRight } from '@fortawesome/pro-light-svg-icons';
	import TabPanel from '@components/TabPanel.vue';
	import { nanoid } from 'nanoid';
	import DebugSection from '@components/DebugSection.vue';
	import { convertEmptyArraysToObjects } from '@modules/workflow/utils/helpers';
	import { ActionTypes, WorkblockSections } from '@modules/workflow/utils/constants';
	import { watchDebounced } from '@vueuse/core/index';

	const workflowStore = useWorkflowStore();

	onBeforeMount(async () => {
		await workflowStore.getWorkblockActions();
	});

	const workblockActions = ref<WorkflowElementActions>(workflowStore.editingWorkblockActions);
	const settings = ref(workflowStore.editingWorkblockTemplate);
	const tab = ref('Settings');
	const workblockActionSchema = ref<{ [key: string]: FormKitSchemaNode[] }>({});

	onMounted(async () => {
		for (const workblockActionSection of Object.values(workblockActions.value)) {
			for (const workblockAction of workblockActionSection) {
				if (workblockAction.actionId && !workblockActionSchema.value[workblockAction.id + '_' + workblockAction.actionId]) {
					workblockActionSchema.value[workblockAction.id + '_' + workblockAction.actionId] = getSchemaForActionId(workblockAction.actionId) ?? ([] as FormKitSchemaNode[]);
				}
			}
		}
		workblockActions.value = convertEmptyArraysToObjects(workblockActions.value, ['pre_actions', 'actions', 'post_actions', 'slots']);
		workblockActions.value = workblockActions.value;
	});

	const tabs = computed<TabPanelTab[]>(() => {
		return [
			{ name: 'Settings', icon: faFolderGear },
			{ name: WorkblockSections.pre_actions, icon: faTurnDownLeft, count: workblockActions.value.pre_actions.length },
			{ name: WorkblockSections.actions, icon: faPlay, count: workblockActions.value.actions.length },
			{ name: WorkblockSections.post_actions, icon: faTurnDownRight, count: workblockActions.value.post_actions.length },
		] as TabPanelTab[];
	});

	function updateWorkblockAction(workblockId: number, workblockActionIndex: number, newActionId: string) {
		if (!workblockActionSchema.value[workblockId + '_' + newActionId]) {
			workblockActionSchema.value[workblockId + '_' + newActionId] = getSchemaForActionId(newActionId) ?? [];
		}
	}

	watchDebounced(
		() => workblockActions.value,
		(updatedActions) => {
			for (const actionSections in WorkblockSections) {
				for (const action of updatedActions[actionSections]) {
					if (action.actionId && parseInt(action.actionId) === ActionTypes.INITIATE_FORM) {
						for (const workflowFormsAddress in workflowStore.workflowForms) {
							if (workflowStore.workflowForms[workflowFormsAddress].id == action.id) {
								workflowStore.workflowForms[workflowFormsAddress].parameters = action.parameters;
							}
						}
					} else if (action.actionId && parseInt(action.actionId) === ActionTypes.CREATE_TRIGGER) {
						for (const workflowTriggersAddress in workflowStore.workflowTriggers) {
							if (workflowStore.workflowTriggers[workflowTriggersAddress].id == action.id) {
								workflowStore.workflowTriggers[workflowTriggersAddress].parameters = action.parameters;
							}
						}
					}
				}
			}
		},
		{ debounce: 1000, deep: true },
	);

	const workblockSettingsSchema: FormKitSchemaNode[] = [
		{
			$formkit: 'text',
			name: 'title',
			label: 'Title',
			maxLength: 60,
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-6': true,
				'px-1': true,
			},
		},
		{
			$formkit: 'group',
			name: 'settings',
			children: [
				{
					$formkit: 'text',
					name: 'detail',
					label: 'Subtitle',
					outerClass: {
						'col-span-12': true,
						'sm:col-span-12': true,
						'lg:col-span-12': true,
						'xl:col-span-6': true,
						'px-1': true,
					},
				},
			],
		},
		{
			$formkit: 'textarea',
			name: 'description',
			label: 'Description',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
				'px-1': true,
			},
		},
		{
			$formkit: 'group',
			name: 'settings',
			children: [
				{
					$formkit: 'group',
					name: 'icon',
					children: [
						{
							$formkit: 'text',
							name: 'iconName',
							label: 'Icon Name',
							outerClass: {
								'col-span-12': true,
								'sm:col-span-12': true,
								'lg:col-span-12': true,
								'xl:col-span-4': true,
								'px-1': true,
							},
						},
						{
							$formkit: 'text',
							name: 'iconClass',
							label: 'Icon Class',
							outerClass: {
								'col-span-12': true,
								'sm:col-span-12': true,
								'lg:col-span-12': true,
								'xl:col-span-4': true,
								'px-1': true,
							},
						},
						{
							$formkit: 'text',
							name: 'backgroundClass',
							label: 'Background Class',
							outerClass: {
								'col-span-12': true,
								'sm:col-span-12': true,
								'lg:col-span-12': true,
								'xl:col-span-4': true,
								'px-1': true,
							},
						},
					],
				},
			],
		},
		{
			$formkit: 'textarea',
			name: 'notes',
			label: 'Builder notes',
			outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true, 'px-1': true },
			placeholder: 'Optional notes about the step',
		},
	];

	function addNewActionToSection(section: keyof typeof workblockActions.value) {
		if (workblockActions.value && Object.hasOwn(workblockActions.value, section)) {
			workblockActions.value[section].push({
				id: nanoid(),
				actionId: undefined,
				parameters: {},
			});
		}
	}

	const makeSchemaKeysUnique = (schema: string) => {
		const resultArr = schema.split(',');
		for (const line in resultArr) {
			if (resultArr[line].includes('"key"')) {
				resultArr[line] = resultArr[line].replace(/"key":".*"/g, '"key":"' + nanoid() + '"');
			}
		}
		return resultArr.join(',');
	};

	function getSchemaForActionId(actionId: string): FormKitSchemaNode[] | undefined {
		for (const action of workflowStore.workblockActions) {
			if (action.id == actionId) {
				const schema = makeSchemaKeysUnique(action.schema);
				return JSON.parse(schema) as FormKitSchemaNode[];
			}
		}
	}
</script>
