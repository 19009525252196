<template>
	<AppLayout>
		<template #header>
			<PageHeader :title="workflowStore.workflow.name" :show-breadcrumbs="true" :base-breadcrumb="baseBreadcrumb" :breadcrumbs="breadcrumbs()">
				<template #actions>
					<button
						class="flex justify-center items-center px-4 py-2 my-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-black bg-white hover:bg-gray-100 focus:outline-none"
						type="button"
						:disabled="workflowStore.loading"
						@click="workflowStore.updateWorkflow"
					>
						Save workflow
						<FontAwesomeIcon :icon="faFloppyDisk" class="h-5 w-5 text-black ml-2" aria-hidden="true" />
					</button>
				</template>
			</PageHeader>
		</template>
		<template #primary>
			<div class="flex flex-col h-full w-full">
				<WorkflowBuilder :allow-edit="true" :workflow-id="route.params.workflow_id.toString()" />
			</div>
		</template>
		<template #secondary_title_text>
			<span v-if="workflowStore.elementSettingsOpen"> Edit workblock element </span>
			<span v-else-if="workflowStore.workflowSettingsOpen"> Edit workflow settings </span>
		</template>
		<template #secondary>
			<ElementSettings v-if="workflowStore.elementSettingsOpen"></ElementSettings>
			<WorkflowSettings v-else-if="workflowStore.workflowSettingsOpen"></WorkflowSettings>
		</template>
	</AppLayout>
</template>

<script lang="ts" setup>
	import AppLayout from '@layouts/AppLayout.vue';
	import { BreadcrumbObject } from '@/types/layout';
	import { useRoute } from 'vue-router';
	import PageHeader from '@components/PageHeader.vue';
	import { faFloppyDisk } from '@fortawesome/pro-light-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { useWorkflowStore } from '@modules/workflow/store';
	import ElementSettings from '@modules/workflow/partials/ElementSettings.vue';
	import WorkflowSettings from '@modules/workflow/partials/WorkflowSettings.vue';
	import WorkflowBuilder from '@modules/workflow/components/WorkflowBuilder.vue';

	const workflowStore = useWorkflowStore();
	const route = useRoute();

	const baseBreadcrumb = {
		route: { name: 'workflow.index' },
		label: 'Workflows',
	} as BreadcrumbObject;

	const breadcrumbs = () => {
		const breadcrumbs = [];

		for (const matchedRoute of route.matched) {
			breadcrumbs.push({
				route: {
					name: matchedRoute.name,
					params: {
						workflow_id: route.params.workflow_id.toString(),
					},
				},
				label: matchedRoute.meta.title ? matchedRoute.meta.title : workflowStore.workflow.name,
			});
		}
		return (breadcrumbs as BreadcrumbObject[]).filter((element) => {
			return Object.keys(element).length !== 0;
		});
	};
</script>