<template>
	<div class="flex flex-col">
		<h1 class="font-bold">Edit branch settings</h1>
		<div class="mb-2">
			<TabPanel v-model="tab" :tabs="tabs" />
		</div>
		<div v-for="(branch, branchIndex) in workflowStore.editingWorkblock.children" :key="branch.branch?.index" class="flex flex-col">
			<div class="flex flex-row justify-between items-center py-1">
				<h2 :class="{ 'col-span-12': true, 'font-semibold': true }">BRANCH {{ branch.branch?.index }}</h2>
				<div class="flex items-center">
					<button type="button" class="rounded-md bg-white hover:text-danger-800 text-danger-500" title="Delete this branch from the element" @click="deleteBranch(branchIndex)">
						<span class="sr-only">Delete branch</span>
						<FontAwesomeIcon :icon="faTrashAlt" aria-hidden="true" class="h-5 w-5" />
					</button>
				</div>
			</div>
			<div :class="{ 'col-span-12': true, rounded: true, border: true, 'b-2': true, 'p-2': true, 'mb-4': true }">
				<FormKit v-if="workflowStore.editingWorkblock.children" v-model="workflowStore.editingWorkblock.children[branchIndex].branch" type="group" :preserve="true">
					<div class="grid grid-cols-12 gap-x-2">
						<FormKitSchema v-if="branch.branch" :schema="branchSchema(branch.branch.index)" />
					</div>
				</FormKit>
			</div>
			<DebugSection>{{ branch?.branch }}</DebugSection>
		</div>
		<div class="flex flex-row justify-end items-center">
			<button type="button" class="flex justify-center items-center px-3 py-1.5 border border-transparent text-base font-medium rounded shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none" @click="addNewBranch">
				<span class="mr-2">Add new branch</span>
				<FontAwesomeIcon :icon="faPlus" aria-hidden="true" class="w-5 h-5" />
			</button>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, onBeforeMount, ref } from 'vue';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { faPlus, faTrashAlt } from '@fortawesome/pro-light-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { WorkflowObjectType, WorkflowSchema } from '@/types/workflow';
	import { makePositionToElements } from '@modules/workflow/utils/helpers';
	import clone from '@utils/useClone';
	import { get } from 'lodash';
	import { nanoid } from 'nanoid';
	import { FormKitSchemaNode } from '@formkit/core';
	import { TabPanelTab } from '@/types/layout';
	import { faSquareChevronDown } from '@fortawesome/pro-solid-svg-icons';
	import TabPanel from '@components/TabPanel.vue';
	import DebugSection from '@components/DebugSection.vue';
	import { FieldConditions, FieldConditionsOperator } from '@/types/form';

	const workflowStore = useWorkflowStore();

	const tab = ref('Branches');

	const tabs = computed<TabPanelTab[]>(() => {
		return [
			{ name: 'Branches', icon: faSquareChevronDown, count: workflowStore.editingWorkblock?.children?.length ?? undefined },
			//{ name: 'Settings', icon: faSquareChevronDown },
		] as TabPanelTab[];
	});

	onBeforeMount(() => {
		for (const branchIndex in workflowStore.editingWorkblock.children) {
			if (!('branch' in workflowStore.editingWorkblock.children[parseInt(branchIndex)])) {
				workflowStore.editingWorkblock.children[parseInt(branchIndex)]['branch'] = {
					index: parseInt(branchIndex) + 1,
					catch_all: false,
					value_variable: undefined,
				};
			}
		}
	});

	const deleteBranch = async (branchIndex: number) => {
		const newWorkflowSchema = clone(workflowStore.workflowSchema);
		const parentElement = get(newWorkflowSchema, workflowStore.editingWorkblockAddress);
		if (parentElement && parentElement.children) {
			parentElement.children.splice(branchIndex, 1);
			const newElementWithPos = makePositionToElements(newWorkflowSchema, { x: 0, y: 0 }, 0) as WorkflowSchema;
			await workflowStore.makeUpdateToActiveWorkflow(newElementWithPos);
		}
	};
	const addNewBranch = async () => {
		const newWorkflowSchema = clone(workflowStore.workflowSchema);
		const parentElement = get(newWorkflowSchema, workflowStore.editingWorkblockAddress);
		if (parentElement) {
			const branchInitChildren = {
				id: nanoid(),
				type: WorkflowObjectType.ROOT,
				position: { x: 0, y: 0 },
				branch: {
					index: workflowStore.editingWorkblock.children ? workflowStore.editingWorkblock.children.length + 1 : 1,
					catch_all: false,
				},
				children: [
					{
						id: nanoid(),
						type: WorkflowObjectType.PLUS,
						position: { x: 0, y: 0 },
					},
				],
			} satisfies WorkflowSchema;
			if (parentElement.children) {
				parentElement.children.push(branchInitChildren);
				const newElementWithPos = makePositionToElements(newWorkflowSchema, { x: 0, y: 0 }, 0) as WorkflowSchema;
				await workflowStore.makeUpdateToActiveWorkflow(newElementWithPos);
			}
		}
	};

	const branchSchema = (index: number) => {
		return [
			{
				id: index + '_catch_all',
				key: index + '_catch_all',
				help: 'Is this branch to be used as the default when no other branches are used',
				meta: [],
				name: 'catch_all',
				label: 'Catch-all branch',
				value: false,
				$formkit: 'toggle',
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true },
				validation: [['required']],
				onValueLabel: 'Yes',
				labelPosition: 'secondary',
				offValueLabel: 'No',
			},
			{
				id: index + '_value_variable',
				key: index + '_value_variable',
				if: '$get(' + index + '_catch_all).value == false',
				help: 'In the format "folder.folder.variable"',
				meta: [],
				name: 'value_variable',
				label: 'Variable to match against',
				$formkit: 'text',
				validationVisibility: 'live',
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true },
				validation: [['required'], ['safeVariableString']],
				placeholder: '',
			},
			{
				id: index + '_selector',
				key: index + '_selector',
				if: '$get(' + index + '_catch_all).value == false',
				help: 'How shall we determine the branch to be used',
				meta: [],
				name: 'selector',
				label: 'Resolve branch decision by',
				simple: true,
				$formkit: 'cardGroup',
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true },
				validation: [['required']],
				cardOptions: [
					{ label: 'Static value', value: 'STATIC' },
					{ label: 'Expression value', value: 'EXPRESSION' },
				],
			},
			{
				id: index + '_input_type',
				key: index + '_input_type',
				if: '($get(' + index + '_catch_all).value == false) && ($get(' + index + '_selector).value == "STATIC")',
				name: 'inputType',
				label: 'Input Type',
				simple: true,
				$formkit: 'cardGroup',
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true },
				validation: [['required']],
				cardOptions: [
					{ label: 'String', value: 'text' },
					{ label: 'Number', value: 'number' },
					{ label: 'Date', value: 'date' },
					{ label: 'DateTime', value: 'datetime-local' },
					{ label: 'Time', value: 'time' },
				],
			},
			{
				id: index + '_value_static',
				key: index + '_value_static',
				if: '($get(' + index + '_catch_all).value == false) && ($get(' + index + '_selector).value == "STATIC") && ($get(' + index + '_input_type).value)',
				help: 'The static value to match against the variable',
				name: 'value_static',
				label: 'Static value',
				$formkit: '$get(inputType).value',
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true },
				validation: [['required']],
			},
			{
				id: index + '_value_operator',
				key: index + '_value_operator',
				if: '($get(' + index + '_catch_all).value == false) && ($get(' + index + '_selector).value == "EXPRESSION")',
				help: 'The operator used to compare to the variable selected',
				name: 'value_operator',
				label: 'Operator',
				$formkit: 'dropdown',
				options: [
					{
						value: FieldConditions.GREATER_THAN,
						label: FieldConditionsOperator[FieldConditions.GREATER_THAN],
					},
					{
						value: FieldConditions.GREATER_THAN_OR_EQUAL,
						label: FieldConditionsOperator[FieldConditions.GREATER_THAN_OR_EQUAL],
					},
					{
						value: FieldConditions.LESS_THAN,
						label: FieldConditionsOperator[FieldConditions.LESS_THAN],
					},
					{
						value: FieldConditions.LESS_THAN_OR_EQUAL,
						label: FieldConditionsOperator[FieldConditions.LESS_THAN_OR_EQUAL],
					},
					{
						value: FieldConditions.EQUAL_TO,
						label: FieldConditionsOperator[FieldConditions.EQUAL_TO],
					},
					{
						value: FieldConditions.STRICT_EQUAL_TO,
						label: FieldConditionsOperator[FieldConditions.STRICT_EQUAL_TO],
					},
					{
						value: FieldConditions.NOT_EQUAL_TO,
						label: FieldConditionsOperator[FieldConditions.NOT_EQUAL_TO],
					},
					{
						value: FieldConditions.STRICT_NOT_EQUAL_TO,
						label: FieldConditionsOperator[FieldConditions.STRICT_NOT_EQUAL_TO],
					},
				],
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true },
				validation: [['required']],
			},
			{
				id: index + '_value_expression',
				key: index + '_value_expression',
				if: '($get(' + index + '_catch_all).value == false) && ($get(' + index + '_selector).value == "EXPRESSION")',
				help: 'The expression to be used to match the variable',
				name: 'value_expression',
				label: 'Expression',
				$formkit: 'text',
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true },
				validationVisibility: 'live',
				validation: [['required'], ['safeVariableString']],
			},
			{
				id: index + '_notes',
				key: index + '_notes',
				$formkit: 'textarea',
				name: 'notes',
				label: 'Builder notes',
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true },
				placeholder: 'Optional notes about the branch',
			},
		] as FormKitSchemaNode[];
	};
</script>
