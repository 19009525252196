<template>
	<div>
		<div class="sm:hidden">
			<label for="tabs" class="sr-only">Select a tab</label>
			<select
				id="tabs"
				name="tabs"
				class="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-500 focus:outline-none focus:ring-primary-500 sm:text-sm"
				@change="(event) => changeTabSelected(event.target.value)"
			>
				<option v-for="tab in tabs" :key="tab.name" :selected="modelValue == tab.name" :value="tab.name">{{ tab.name }}</option>
			</select>
		</div>
		<div class="hidden sm:block">
			<div class="border-b border-gray-200">
				<nav class="-mb-px flex" aria-label="Tabs">
					<span
						v-for="tab in tabs"
						:key="tab.name"
						:class="[
							modelValue == tab.name ? 'border-primary-500 text-primary-600' : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
							'group inline-flex items-center border-b-2 py-2.5 px-4 text-sm font-medium cursor-pointer hover:bg-gray-100',
						]"
						:aria-current="modelValue == tab.name ? 'page' : undefined"
						@click="changeTabSelected(tab.name)"
					>
						<FontAwesomeIcon v-if="tab.icon" :icon="tab.icon" aria-hidden="true" :class="[modelValue == tab.name ? 'text-primary-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5 select-none pointer-events-auto']" />
						<span class="select-none pointer-events-auto whitespace-nowrap">{{ tab.name }}</span>
						<span
							v-if="tab.count"
							:class="[modelValue == tab.name ? 'bg-primary-100 text-primary-600' : 'bg-primary-50 text-gray-900', 'ml-3 hidden rounded-full py-0.5 px-2.5 text-xs font-medium md:inline-block select-none pointer-events-auto']"
							>{{ tab.count }}</span
						>
					</span>
				</nav>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { defineProps } from 'vue';
	import { TabPanelTab } from '@/types/layout';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

	const emits = defineEmits(['update:modelValue']);

	const props = defineProps<{
		tabs: TabPanelTab[];
		modelValue: string;
	}>();

	const changeTabSelected = (tabName: string) => {
		emits('update:modelValue', tabName);
	};
</script>

<style scoped></style>
