<template>
	<div class="flex flex-col h-full">
		<div class="flex flex-row justify-end items-center space-x-4 overflow-visible sticky top-0 z-20 border-b border-dashed bg-gray-50">
			<button
				type="button"
				class="items-center text-sm font-medium rounded-md focus:outline-none text-danger-400 underline mr-3 my-2"
				@click="workflowStore.deleteElementFromActiveWorkflow(workflowStore.workflowEditingElementAddress, workflowStore.workflowEditingElementIndex)"
			>
				Delete this workblock
			</button>
		</div>
		<div class="px-4 py-2 overflow-y-auto flex flex-col h-full">
			<BranchSettings v-if="workflowStore.editingWorkblock?.type === WorkflowObjectType.BRANCH" :key="workflowStore.editingWorkblock.id" />
			<StepSettings v-if="workflowStore.editingWorkblock?.type === WorkflowObjectType.STEP" :key="workflowStore.editingWorkblock.id" />
			<ExitSettings v-if="workflowStore.editingWorkblock?.type === WorkflowObjectType.EXIT" :key="workflowStore.editingWorkblock.id" />
			<EntrySettings v-if="workflowStore.editingWorkblock?.type === WorkflowObjectType.ENTRY" :key="workflowStore.editingWorkblock.id" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useWorkflowStore } from '@modules/workflow/store';
	import BranchSettings from '@modules/workflow/components/BranchSettings.vue';
	import { WorkflowObjectType } from '@/types/workflow';
	import StepSettings from '@modules/workflow/components/StepSettings.vue';
	import EntrySettings from '@modules/workflow/components/EntrySettings.vue';
	import ExitSettings from '@modules/workflow/components/ExitSettings.vue';

	const workflowStore = useWorkflowStore();
</script>
