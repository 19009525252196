<template>
	<div class="flex flex-col">
		<span class="font-semibold text-xl">Exit settings for {{ convertJSAddressToPHPAddress(workflowStore.editingWorkblockAddress) }}</span>
		<div :class="{ 'col-span-12': true, rounded: true, border: true, 'b-2': true, 'py-2': true, 'px-2': true, 'mt-4': true, 'mb-4': true }">
			<FormKit v-model="settings" type="form" :actions="false">
				<FormKit type="text" name="label" label="Exit point label" value="" />
				<FormKit type="color" name="color" label="Exit point colour" />
				<FormKit
					name="notes"
					type="textarea"
					:classes="{
						outer: 'col-span-12 sm:col-span-12 lg:col-span-12 xl:col-span-12',
					}"
					label="Builder notes"
					placeholder="Optional notes about the action"
				/>
			</FormKit>
		</div>
		<DebugSection>{{ settings }}</DebugSection>
	</div>
</template>

<script setup lang="ts">
	import { ref, watch } from 'vue';
	import DebugSection from '@components/DebugSection.vue';
	import { useWorkflowStore } from '@modules/workflow/store';
	import { convertJSAddressToPHPAddress } from '../utils/helpers';

	const workflowStore = useWorkflowStore();

	const settings = ref(workflowStore.editingWorkblock);

	watch(settings, async (newSettingsValue) => {
		workflowStore.workflowExits[convertJSAddressToPHPAddress(workflowStore.editingWorkblockAddress)] = {
			label: newSettingsValue.label,
			color: newSettingsValue.color,
		};
	});
</script>
