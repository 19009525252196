<template>
	<div class="h-full flex flex-col">
		<div class="h-full px-4 overflow-y-auto">
			<TabPanel v-model="tab" :tabs="tabs" />

			<section v-show="tab == 'Settings'">
				<FormKit id="workflowSettings" v-model="data" type="form" :actions="false" class="flex w-full">
					<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
						<FormKitSchema :schema="workflowSchema" />
					</div>
				</FormKit>
				<FormKit id="workflowSettings" v-model="settings" type="form" :actions="false" class="flex w-full">
					<div class="grid grid-cols-12 gap-x-2 my-4">
						<FormKitSchema :schema="workflowSettingsSchema" />
					</div>
				</FormKit>
				<DebugSection>{{ data }}{{ workflowStore.workflowSettings }}</DebugSection>
			</section>
			<section v-show="tab == 'Forms'">
				<div v-for="(form, index) in workflowStore.workflowForms" :key="index">
					<h1 class="font-bold text-xl">{{ index }}</h1>
					<br />
					<pre>{{ form }}</pre>
				</div>
			</section>
			<section v-show="tab == 'Triggers'">
				<div v-for="(trigger, index) in workflowStore.workflowTriggers" :key="index">
					<h1 class="font-bold text-xl">{{ index }}</h1>
					<br />
					<pre>{{ trigger }}</pre>
				</div>
			</section>
			<section v-show="tab == 'Exits'">
				<div v-for="(exit, index) in workflowStore.workflowExits" :key="index">
					<h1 class="font-bold text-xl">{{ index }}</h1>
					<br />
					<pre>{{ exit }}</pre>
				</div>
			</section>
			<section v-show="tab == 'Variables'">
				<VariableSelector
					:objects="workflowStore.workflowVariables"
					item-key="id"
					:can-create="true"
					:selected-variable="selectedVariableAddress"
					:open-groups="openGroups"
					@click="(variableAddress: string, isGroup: boolean) => selectVariable(variableAddress, isGroup)"
					@create="createVariable"
				></VariableSelector>
				<TransitionRoot as="template" :show="variableViewerOpen">
					<Dialog as="div" class="fixed z-50 inset-0 overflow-y-auto" @close="closeVariableViewer">
						<div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
							<TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
								<DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
							</TransitionChild>

							<!-- This element is to trick the browser into centering the modal contents. -->
							<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
							<TransitionChild
								as="template"
								enter="ease-out duration-300"
								enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
								enter-to="opacity-100 translate-y-0 sm:scale-100"
								leave="ease-in duration-200"
								leave-from="opacity-100 translate-y-0 sm:scale-100"
								leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
							>
								<div class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4/5 sm:w-full sm:p-6 z-50">
									<div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
										<button type="button" class="bg-white rounded-md text-gray-400 hover:text-gray-500" @click="closeVariableViewer">
											<span class="sr-only">Close</span>
											<FontAwesomeIcon :icon="faXmark" aria-hidden="true" class="h-6 w-6" />
										</button>
									</div>
									<div class="sm:flex sm:items-start">
										<div class="flex flex-row flex-grow items-center">
											<div class="flex flex-row flex-grow justify-center">
												<div class="text-center sm:ml-4 sm:text-left justify-center">
													<DialogTitle as="h1" class="text-xl font-medium text-gray-900">Variable Viewer</DialogTitle>
												</div>
											</div>
											<div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10"></div>
										</div>
									</div>
									<div class="flex flex-row flex-grow">
										<VariableViewer v-if="selectedVariable" :variable-address="selectedVariableAddress" :variable="selectedVariable"></VariableViewer>
									</div>
									<div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
										<button
											type="button"
											class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
											@click="saveSelectedVariable"
										>
											Save
										</button>
										<button
											type="button"
											class="w-full inline-flex justify-center rounded-md border-transparent shadow-sm px-4 py-2 text-base font-medium hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm bg-white border-2 border-red-500 text-red-500"
											@click="closeVariableViewer"
										>
											Close
										</button>
									</div>
								</div>
							</TransitionChild>
						</div>
					</Dialog>
				</TransitionRoot>
				<DebugSection>
					{{ workflowStore.workflowVariables }}
				</DebugSection>
			</section>
			<section v-show="tab == 'Theme'"></section>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useWorkflowStore } from '@modules/workflow/store';
	import { FormKitSchemaNode } from '@formkit/core';
	import { BuildElementBox } from '@modules/form/utils/options';
	import { computed, nextTick, ref, watch } from 'vue';
	import slugify from '@utils/useSlugify';
	import TabPanel from '@components/TabPanel.vue';
	import { faSquareChevronDown } from '@fortawesome/pro-solid-svg-icons';
	import { TabPanelTab } from '@/types/layout';
	import DebugSection from '@components/DebugSection.vue';
	import VariableSelector from '@modules/workflow/components/VariableSelector.vue';
	import { WorkflowVariable } from '@/types/workflow';
	import { get } from 'lodash';
	import { faXmark } from '@fortawesome/pro-light-svg-icons';
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
	import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';
	import VariableViewer from '@modules/workflow/components/VariableViewer.vue';

	const workflowStore = useWorkflowStore();

	const data = ref(workflowStore.workflow);
	const settings = ref(workflowStore.workflowSettings);

	const selectedVariableAddress = ref<string>('');
	const selectedVariable = ref<WorkflowVariable | unknown>();
	const openGroups = ref<string[]>(['forms']);
	const variableViewerOpen = ref<boolean>(false);

	const tab = ref('Settings');

	const closeVariableViewer = () => {
		variableViewerOpen.value = false;
		nextTick(() => {
			selectedVariableAddress.value = '';
		});
	};

	const tabs = computed<TabPanelTab[]>(() => {
		return [
			{ name: 'Settings', icon: faSquareChevronDown },
			{ name: 'Forms', icon: faSquareChevronDown, count: workflowStore?.workflowForms.length ?? undefined },
			{ name: 'Triggers', icon: faSquareChevronDown, count: workflowStore?.workflowTriggers.length ?? undefined },
			{ name: 'Variables', icon: faSquareChevronDown },
			{ name: 'Exits', icon: faSquareChevronDown },
			{ name: 'Theme', icon: faSquareChevronDown },
		] as TabPanelTab[];
	});

	watch(
		() => workflowStore.workflow.name,
		(newValue) => {
			if (newValue) {
				workflowStore.workflow.reference_name = slugify(newValue);
			}
		},
	);

	const workflowSchema: FormKitSchemaNode[] = [
		...BuildElementBox('workflow settings', [
			{
				$formkit: 'text',
				outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true, 'px-1': true },
				validation: [['required'], ['length:0,255']],
				id: 'name',
				name: 'name',
				label: 'Workflow name',
				placeholder: 'Enter a name for the workflow being created',
			},
			{
				$formkit: 'text',
				outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-6': true, 'xl:col-span-6': true, 'px-1': true },
				validation: [['required'], ['length:0,255']],
				id: 'reference_name',
				name: 'reference_name',
				label: 'Workflow reference name',
				readonly: true,
				disabled: true,
			},
			{
				$formkit: 'text',
				outerClass: { 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'px-1': true },
				id: 'description',
				name: 'description',
				label: 'Workflow description',
				placeholder: 'Enter a description for the workflow being created',
			},
		]),
	];
	const workflowSettingsSchema: FormKitSchemaNode[] = [
		...BuildElementBox('', [
			{
				$formkit: 'textarea',
				name: 'notes',
				label: 'Builder notes',
				outerClass: { 'col-span-12': true, 'lg:col-span-12': true, 'sm:col-span-12': true, 'xl:col-span-12': true, 'px-1': true },
				placeholder: 'Optional notes about the workflow',
			},
		]),
	];

	const selectVariable = (variableAddress: string, isGroup: boolean) => {
		if (isGroup) {
			if (openGroups.value.includes(variableAddress)) {
				openGroups.value = openGroups.value?.filter((address) => address !== variableAddress);
			} else {
				openGroups.value.push(variableAddress);
			}
		}

		selectedVariableAddress.value = variableAddress;
		if (!isGroup) {
			nextTick(() => {
				variableViewerOpen.value = true;
			});
		}
	};

	watch(selectedVariableAddress, async (newVariableAddress) => {
		selectedVariable.value = get(workflowStore.workflowVariables, newVariableAddress);
	});

	const createVariable = async (variableAddressToCreateVariable: string | boolean, isGroup: boolean) => {
		const newVariable = workflowStore.addNewWorkflowVariable(variableAddressToCreateVariable, isGroup);
		await nextTick(() => {
			selectVariable(newVariable, isGroup);
		});
	};

	const saveSelectedVariable = async () => {
		// take the selected variable and update the address is the variable name has changed
		const currentVariableName = selectedVariableAddress.value.substring(selectedVariableAddress.value.lastIndexOf('.') + 1, selectedVariableAddress.value.length);
		const parentVariableAddress = selectedVariableAddress.value.substring(0, selectedVariableAddress.value.lastIndexOf('.'));

		const parentVariable = parentVariableAddress == '' ? workflowStore.workflowVariables : get(workflowStore.workflowVariables, parentVariableAddress);
		if (!(selectedVariable.value['name'] in parentVariable)) {
			parentVariable[selectedVariable.value['name']] = selectedVariable.value;
			delete parentVariable[currentVariableName];
		}

		await nextTick(() => {
			closeVariableViewer();
		});
	};

	const workflowVariables = computed(() => {
		const variables = [];
		for (const workflowVariableIndex in workflowStore.workflowVariables) {
			variables.push({
				label: workflowStore.workflowVariables[workflowVariableIndex].name + ' [' + workflowVariableIndex + ']',
				value: workflowVariableIndex,
			});
			if (workflowStore.workflowVariables[workflowVariableIndex].children && workflowStore.workflowVariables[workflowVariableIndex].children.length > 0) {
				variables.concat(getChildrenVariables(workflowVariableIndex + '.children'));
			}
		}
		return variables;
	});

	const getChildrenVariables = (address: string) => {
		const childVariables = [];
		const childVariable = get(workflowStore.workflowVariables, address);
		for (const childIndex in childVariable) {
			childVariables.push({
				label: childVariable[childIndex].name + ' [' + address + '.children.' + childIndex + ']',
				value: address + '.' + childIndex,
			});
			if (childVariable[childIndex].children && childVariable[childIndex].children.length > 0) {
				childVariables.concat(getChildrenVariables(address + '.' + childIndex + '.children'));
			}
		}
		return childVariables;
	};
</script>
